import React from 'react';
import ContactForm from 'components/ContactForm/ContactForm';
import { getFirebase } from 'utils/firebase';
import { getLangFile } from 'utils/lang';
import AnimatedLink from 'components/AnimatedLink/AnimatedLink';
import { formEntry } from 'constants/form';
import './ContactPage.scss';
class ContactPage extends React.Component {
  componentDidMount() {
    const lazyApp = import('firebase/app');
    const lazyDatabase = import('firebase/database');

    Promise.all([lazyApp, lazyDatabase]).then(([firebase]) => {
      this.setState({ database: getFirebase(firebase).database() });
    });
  }

  state = {
    status: '',
    message: '',
    database: null,
  };

  subscribe = (formData) => {
    this.setState(
      {
        status: 'sending',
      },
      () => {
        this.state.database.ref('messages').push(
          {
            [formEntry.email]: formData[formEntry.email],
            [formEntry.fullName]: formData[formEntry.fullName],
            [formEntry.company]: formData[formEntry.company],
            [formEntry.projectDetails]: formData[formEntry.projectDetails],
          },
          (error) => {
            if (error) {
              // The write failed...
              this.setState({
                status: 'error',
                message: `Error message: ${error}`,
              });
            } else {
              this.requestSlack();
              // Data saved successfully!
              this.setState({
                status: 'success',
              });
            }
          }
        );
      }
    );
  };

  requestSlack = () => {
    fetch('https://hooks.slack.com/services/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', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        username: 'CONTACT',
        channel: '#activity-contact',
        icon_emoji: ':email:',
        text: 'コーポレートページから問合せが来ました',
      }),
    })
      .then((response) => {
        return console.log('message is sent to slack');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const t = getLangFile().contactPage;
    const { message, status } = this.state;
    return (
      <div className="ContactPage">
        <h3>{t.title}</h3>
        <p>
          {t.subtitle}&nbsp;&nbsp;
          <AnimatedLink text={t.emailButton} href="mailto:hello@alpha.inc" />
        </p>
        <ContactForm
          status={status}
          message={message}
          onValidated={this.subscribe}
          onSubmitted={this.requestSlack}
        />
      </div>
    );
  }
}

export default ContactPage;
